import $ from 'jquery';
import { Datepicker, DateRangePicker } from 'vanillajs-datepicker';
import 'eonasdan-bootstrap-datetimepicker';
import { OBSERVER } from '../formbuilder';
import AddressGoogle from './addressGoogle';
import isMobile from './helper';
import moment from 'moment';

// Ajoute un attribut ayant la valeur du champ
export function inputsAndTextareaLabel(classeContainerFormBuilder) {
  // eslint-disable-next-line max-len
  const elementsString = `${classeContainerFormBuilder} input[type="text"],${classeContainerFormBuilder} input[type="tel"],${classeContainerFormBuilder} input[type="email"],${classeContainerFormBuilder} textarea`;
  const formElements = document.querySelectorAll(elementsString);
  const formElementsLength = formElements.length;
  let i;

  for (i = 0; i < formElementsLength; i += 1) {
    formElements[i].parentNode.parentNode.dataset[`${formElements[i].tagName.toLowerCase()}value`] = formElements[i].value;
  }

  const setValue = (e) => {
    e.target.parentNode.parentNode.dataset[`${e.target.tagName.toLowerCase()}value`] = e.target.value;
  };

  OBSERVER.add({
    name: 'inputsAndTextareaLabel',
    event: 'input',
    target: elementsString,
    function: setValue,
  });

  OBSERVER.on('inputsAndTextareaLabel');
}

// Modification de la hauteur d'un textarea selon son contenu
export function textareaHeight(classeContainerFormBuilder) {
  const onInput = (e) => {
    e.currentTarget.style.height = '5px';
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight <= 43 ? 43 : e.currentTarget.scrollHeight}px`;
  };

  OBSERVER.add({
    name: 'textareaHeight',
    event: 'input',
    target: `${classeContainerFormBuilder} textarea`,
    function: onInput,
  });

  OBSERVER.on('textareaHeight');
}

// Création du custom select (doc: https://github.com/pytesNET/tail.select)
export function tailSelect(classeContainerFormBuilder) {
  const pathImagesIcons = '/plugins/blanko/forms/assets/images/icons/';

  // Ajouter les images dans les options du Tail Select
  let i;
  let j;
  const selectBoxes = document.querySelectorAll(`${classeContainerFormBuilder} .tail-select`);
  const selectBoxesLength = selectBoxes.length;

  // Looper dans chaques boites de sélections Tail Select
  for (i = 0; i < selectBoxesLength; i += 1) {
    const tailSelectItems = selectBoxes[i].querySelectorAll('.dropdown-option');
    const nativeSelect = selectBoxes[i].previousElementSibling;
    const nativeSelectItems = nativeSelect.querySelectorAll('option:not(:first-child)');

    // Ajouter l'icone en symbole
    const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `${pathImagesIcons}symbols.svg#ico-pointer`);
    svgElem.appendChild(useElem);
    selectBoxes[i].appendChild(svgElem);

    // Looper dans chaques item du Tail Select
    for (j = 0; j < nativeSelectItems.length; j += 1) {
      const imgPath = nativeSelectItems[j].dataset.image;
      if ((typeof imgPath !== 'undefined') && imgPath !== '') {
        const newImage = document.createElement('img');
        newImage.src = imgPath;
        tailSelectItems[j].classList.add('has-image');
        tailSelectItems[j].appendChild(newImage);
      }
    }
  }
}

// Initialisation du champ de timepicker
export function timepicker(classeContainerFormBuilder) {
  if (!isMobile()) {
    $(`${classeContainerFormBuilder} .c-input-field.c-input-field--timepicker input`).datetimepicker({
      format: 'HH:mm',
      icons: {
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
      },
    });
  }
}

// Retourne un tableau de dates selon les restrictions
function getEnabledDates(calendar, range = false) {
  const ENABLED_DATES = [];

  let restrictions = null;
  if (range) {
    restrictions = calendar.dataset.datesRangeRestrictions;
  } else {
    restrictions = calendar.dataset.datesRestrictions;
  }

  if (JSON.parse(restrictions)) {
    const data = JSON.parse(restrictions)[0];

    let start = null;
    let end = null;

    /* Date de début d'intervalle */
    if ((data.dates_restrictions_start_type === '1') && (data.dates_start_specific != null)) { // Date de début spécifique
      const date = data.dates_start_specific.split(' ')[0]; // Retrait de l'heure
      const EXPLODED_DATE = date.split('-');
      start = moment().set({ year: EXPLODED_DATE[0], month: EXPLODED_DATE[1] - 1, date: EXPLODED_DATE[2] });
    } else if ((data.dates_restrictions_start_type === '0') && (data.dates_start_delay != null)) { // Nombre de jours avant ou après la date courante
      start = (data.dates_start_delay !== 0) ? moment().add(data.dates_start_delay, 'days') : moment();
    }

    /* Date de fin d'intervalle */
    if ((data.dates_restrictions_end_type === '1') && (data.dates_end_specific != null)) { // Date de fin spécifique
      const date = data.dates_end_specific.split(' ')[0]; // Retrait de l'heure
      const EXPLODED_DATE = date.split('-');
      end = moment().set({ year: EXPLODED_DATE[0], month: EXPLODED_DATE[1] - 1, date: EXPLODED_DATE[2] });
    } else if ((data.dates_restrictions_end_type === '0') && (data.dates_end_delay != null)) { // Nombre de jours après la date de début
      if (data.dates_end_delay > 0) {
        end = start.clone().add(data.dates_end_delay, 'days');
      } else {
        end = start.clone(); // La date de début
      }
    }

    // Si on a au moins une date spécifié  ou  des restriction de jours de la semaine
    if (start || end || (data.dates_week_days instanceof Array)) {
    // Pas de date de début spécifiée (on prend 1 an dans le passé)
      if (!start) {
        start = moment().add(-365, 'days');
      }
      if (!end) { // Pas de date de fin spécifiée (on prend 1 an dans le futur)
        end = moment().add(365, 'days');
      }

      /* Restrictions de jours de la semaine */
      if (data.dates_week_days instanceof Array) {
        data.dates_week_days.forEach((day) => { // On boucle sur chaque jour de la semaine précisé
          const DAY_OF_WEEK = start.clone().day(day);
          if (DAY_OF_WEEK.isAfter(start) || DAY_OF_WEEK.isSame(start)) { // Si le jour de cette semaine est dans l'intervalle de dates ou pas
            ENABLED_DATES.push(DAY_OF_WEEK.format('D-M-YYYY'));
          }
          DAY_OF_WEEK.add(7, 'days'); // on avance d'une semaine
          while (DAY_OF_WEEK.isBefore(end) || DAY_OF_WEEK.isSame(end)) {
            ENABLED_DATES.push(DAY_OF_WEEK.format('D-M-YYYY'));
            DAY_OF_WEEK.add(7, 'days');
          }
        });
      } else { // Pas de jours précis (on prend tous les jours)
        const START_CLONE = start.clone();
        while (START_CLONE.isBefore(end) || START_CLONE.isSame(end)) {
          ENABLED_DATES.push(START_CLONE.format('D-M-YYYY'));
          START_CLONE.add(1, 'days');
        }
      }
    }
  }

  return ENABLED_DATES;
}

// Initialisation du champ de calendrier
export function calendarRangeInput(classeContainerFormBuilder = '') {
  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  const daterangepickers = [];
  const RANGECALENDARS = document.querySelectorAll(`${classeContainerFormBuilder} [data-dates-range-restrictions]`);

  RANGECALENDARS.forEach((rangeCalendar) => {
    const ENABLED_DATES = getEnabledDates(rangeCalendar, true);
    const daterangepickerWrapper = rangeCalendar.querySelector('.js-daterangepickerWrapper');

    daterangepickers.push(new DateRangePicker(daterangepickerWrapper, {
      language: document.querySelector('html').lang,
      prevArrow: '<svg><use xlink:href="/plugins/blanko/forms/assets/images/icons/symbols.svg#ico-pointer"></use></svg>',
      nextArrow: '<svg><use xlink:href="/plugins/blanko/forms/assets/images/icons/symbols.svg#ico-pointer"></use></svg>',
      format: 'd MM yyyy',
      todayHighlight: true,
      clearButton: true,
      datesDisabled: (date) => {
        // Rendre seulement les dates spécifié cliquables
        if (ENABLED_DATES.length) {
          const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
          return ENABLED_DATES.indexOf(allDates) === -1;
        }
        // Toutes les dates sont cliquables
        return false;
      },
    }));
  });

  const onChangeDate = (e) => {
    // S'il y a une longueur maximale de l'intervalle
    const MAX_LENGTH = e.currentTarget.parentElement.dataset.datesMaxInterval;
    if (MAX_LENGTH) {
      // On va chercher l'objet DateRangePicker concerné
      for (let i = 0; i < daterangepickers.length; i += 1) {
        if (e.currentTarget === daterangepickers[i].element) {
          const DATE = daterangepickers[i].datepickers[0].getDate('dd-mm-yyyy'); // La date sélectionnée
          if (DATE) {
            const EXPLODED_DATE = DATE.split('-');
            const start = moment().set({ year: EXPLODED_DATE[2], month: EXPLODED_DATE[1] - 1, date: EXPLODED_DATE[0] });
            const end = start.clone().add(MAX_LENGTH - 1, 'days');

            // Les dates cliquables selon la sélection et le max de jours
            let CLICKABLE_DATES = [];
            const START_CLONE = start.clone();
            while (START_CLONE.isBefore(end) || START_CLONE.isSame(end)) {
              CLICKABLE_DATES.push(START_CLONE.format('D-M-YYYY'));
              START_CLONE.add(1, 'days');
            }

            // Filtration des dates cliquables selon les restrictions de dates de base du calendrier
            const rangeCalendar = e.currentTarget.closest('[data-dates-range-restrictions]');
            const ENABLED_DATES = getEnabledDates(rangeCalendar, true);
            if (ENABLED_DATES.length) {
              // On garde seulement les dates qui font partie des deux tableaux
              CLICKABLE_DATES = CLICKABLE_DATES.filter((date) => (ENABLED_DATES.indexOf(date) !== -1));
            }

            // On applique les nouvelles restrictions de dates
            daterangepickers[i].setOptions({
              datesDisabled: (date) => {
                // Rendre seulement les dates spécifié cliquables
                if (CLICKABLE_DATES.length) {
                  const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
                  return CLICKABLE_DATES.indexOf(allDates) === -1;
                }
                // Toutes les dates sont cliquables
                return false;
              },
            });
          }
        }
      }
    }
  };

  const onClear = (e) => {
    // On va chercher l'objet DateRangePicker concerné
    for (let i = 0; i < daterangepickers.length; i += 1) {
      if (e.currentTarget.closest('.js-daterangepickerWrapper') === daterangepickers[i].element) {
        const rangeCalendar = e.currentTarget.closest('[data-dates-range-restrictions]');
        const ENABLED_DATES = getEnabledDates(rangeCalendar, true);

        // On applique les restrictions de dates de base
        daterangepickers[i].setOptions({
          datesDisabled: (date) => {
            // Rendre seulement les dates spécifié cliquables
            if (ENABLED_DATES.length) {
              const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
              return ENABLED_DATES.indexOf(allDates) === -1;
            }
            // Toutes les dates sont cliquables
            return false;
          },
        });
      }
    }
  };

  OBSERVER.add({
    name: 'input-calendar',
    events: 'changeDate',
    targets: '.js-daterangepickerWrapper',
    function: onChangeDate,
  });
  OBSERVER.add({
    name: 'input-calendar',
    events: 'click',
    targets: '.clear-button',
    function: onClear,
  });
  OBSERVER.on('input-calendar');
}

// Initialisation du champ de calendrier
export function calendarInput(classeContainerFormBuilder = '') {
  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  const CALENDARS = document.querySelectorAll(`${classeContainerFormBuilder} [data-dates-restrictions]`);
  CALENDARS.forEach((calendar) => {
    const ENABLED_DATES = getEnabledDates(calendar, false);
    const datepickerInput = calendar.querySelector('.js-input-datepicker');

    // eslint-disable-next-line no-unused-vars
    const datepicker = new Datepicker(datepickerInput, {
      language: document.querySelector('html').lang,
      prevArrow: '<svg><use xlink:href="/plugins/blanko/forms/assets/images/icons/symbols.svg#ico-pointer"></use></svg>',
      nextArrow: '<svg><use xlink:href="/plugins/blanko/forms/assets/images/icons/symbols.svg#ico-pointer"></use></svg>',
      format: 'd MM yyyy',
      todayHighlight: true,
      autohide: true,
      datesDisabled: (date) => {
        // Rendre seulement les dates spécifié cliquables
        if (ENABLED_DATES.length) {
          const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
          return ENABLED_DATES.indexOf(allDates) === -1;
        }
        // Toutes les dates sont cliquables
        return false;
      },
    });
  });
}

// Google Autocomplete pour le champ Adresse
function addressAutocomplete(classeContainerFormBuilder) {
  const adressFields = $(`${classeContainerFormBuilder} .localisation_field`).get();
  adressFields.forEach((adressField) => {
    // eslint-disable-next-line no-new
    new AddressGoogle(adressField);
  });
}

// Le package à appliquer pour les formulaires
export function formsPackage(classeContainerFormBuilder) {
  inputsAndTextareaLabel(classeContainerFormBuilder);
  textareaHeight(classeContainerFormBuilder);
  tailSelect(classeContainerFormBuilder);
  timepicker(classeContainerFormBuilder);
  addressAutocomplete(classeContainerFormBuilder);
}
